.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
}

.product_url {
  width: 50%;
}

.body_format {
  margin-left: 5%;
  margin-right: 5%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo_img {
  width: 50%;
  margin-top: 5%;
}

.App-footer {
  background-color: #282c34;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .logo_img {
    width: 90%;
    margin-top: 10%;
  }
  .product_url {
    width: 80%;
  }
}

.pointer {
  cursor: pointer;
}

.color-light-red {
  color: #bf0603;
}

.color-dark-red {
  color: #8d0801;
}

.color-white {
  color: white;
}

.color-yellow {
  color: #f4bd3d;
}

.top-margin {
  margin-top: 10px;
}

.btn-primary, .btn-primary:active, .btn-primary:visited {
  background-color: #bf0603 !important;
  border-color: #bf0603 !important;
}

.btn-primary:hover {
  background-color: #8d0801 !important;
  border-color: #8d0801 !important;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
}

.btn-warning, .btn-warning:active, .btn-warning:visited {
  background-color: #f4bd3d !important;
  border-color: #f4bd3d !important;
  /*color: white;*/
}

.btn-warning:hover {
  background-color: #c08a0b !important;
  border-color: #c08a0b !important;
  /*color: white;*/
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
}

.btn-secondary, .btn-secondary:active, .btn-secondary:visited {
  background-color: #282c34 !important;
  border-color: #f4bd3d !important;
  color: white;
}

.btn-secondary:hover {
  background-color: #1d2026 !important;
  border-color: #c08a0b !important;
  color: white;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
}